import { AppConsts } from './../AppConsts';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppSessionService } from '@shared/session/app-session.service';

@Injectable()
export class RedirectGuard implements CanActivate {

  constructor(private router: Router, private _sessionService: AppSessionService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (!this._sessionService.user) {
      this.router.navigate(['/account/login']);
      return false;
    }


    //window.location.href = route.data['externalUrl'];
    window.location.href = AppConsts.avlMapUrl;

    return true;

  }
}